/* eslint-disable */
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FlightIcon from '@mui/icons-material/Flight';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DropdownMenu from './Dropdown_Nav';

export default function Navbar({ item_list }) {
  return (
    <AppBar position="sticky">
      <Toolbar sx={{ justifyContent: 'space-between'}}>
        <Link href="/" color="#fff" underline="none">
          <FlightIcon sx={{ mr: 2 }} />
        </Link>
        <Link href="/" color="#fff" underline="none">
          <Typography variant="h6" color="inherit" noWrap>
            Jet Set Travel Guides
          </Typography>
        </Link>

        <DropdownMenu item_list={item_list} />
      </Toolbar>
    </AppBar>
  );
}
