// useFetch.ts
import { useState, useEffect } from 'react';

interface FetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}

function useFetch<T>(url: string): FetchResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    fetch(url)
      .then(response => {
        if (!response.ok) throw Error('Network response was not ok ' + response.statusText);
        return response.json();
      })
      .then(data => {
        if (!url.includes("places")) {
          data.sort((a, b) => a.name.localeCompare(b.name));
        }
        // console.log(data);
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [url]);  // The effect will re-run if the url changes

  return { data, loading, error };
}

export default useFetch;
