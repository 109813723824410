/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { capitalize } from '@mui/material/utils';
import YouTube from "react-youtube";
import Cards from './Cards';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Album({item_list}) {

  return (
    <Container>
      <Helmet>
        <title>Jet Set Travel Guides | Best Hotels</title>
        {/* other metadata */}
      </Helmet>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Jet Set Travel Guides
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Welcome to Jet Set Travel Guides - discover the world's hidden gems with us! Dive into our curated guides on the world's best hotels, restaurants, nightlife, and things to do.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 4 }} maxWidth="md">
          {/* End hero unit */}
          <Cards item_list={item_list}/>
        </Container>
      </main>
      <Footer/>
    </Container>
  );
}
