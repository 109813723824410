import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, Typography } from '@mui/material';


export default function DropdownMenu({ item_list }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (item_list.loading) {
    // Render loading indicators
    return (
      <IconButton color="inherit" edge="end" disabled>
        <CircularProgress size={24} color="inherit" />
      </IconButton>
    );
  }

  if (item_list.error) {
    // Render error message
    return <div>Error: {item_list.error.message}</div>;
  }

  return (
    <>
      {/* Add a menu button */}
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
      >
        <MenuIcon />
      </IconButton>

      {/* Dropdown menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {item_list.data.map((item, index) => (
          <MenuItem key={index} onClick={handleMenuClose}>
            <Link href={"/places/" + item.name} underline="none">
                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                    {item.name}
                </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
