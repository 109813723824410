// import logo from "./logo.svg";
import "./App.css";
import Album from "./Album.tsx";
import Detail from "./Detail.tsx";
import { Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './Navbar';
import useFetch from './useFetch.ts';
import usePageTracking from './Analytics';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


function App() {
  usePageTracking();
  const item_list = useFetch("https://d35641btdynz0e.cloudfront.net/cities_list.json");
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />  
        <div>
          <Navbar item_list={item_list}/>
          <Routes>
            <Route 
                path="/places/:itemName"
                component={Detail}
                element={<Detail/>}
            />
            <Route 
              path="/"
              element={<Album item_list={item_list}/>}
            />
          </Routes>
        </div>
    </ThemeProvider>
  )
}

export default App;
