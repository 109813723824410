/* eslint-disable */
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";



function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
          Jet Set Travel Guides
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


export default function Footer() {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 6, bottom: 0, width: '100%' }} component="footer" position='relative' >
            <Copyright />
        </Box>
    )
}
