/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { capitalize } from '@mui/material/utils';
import YouTube from "react-youtube";
import Cards from './Cards';
import Footer from './Footer';
import useFetch from './useFetch.ts';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Helmet } from 'react-helmet';


export default function Detail(props) {
    const { itemName } = useParams();
    const { data, loading, error } = useFetch(`https://jetsettravelguides.com/api/places/${itemName}/`);

    const youtubeOptions = {
      height: '315',
      width: '560',
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };
    const containerStyle = {
        width: '100%',  // or any other percentage
        position: 'relative',
        paddingBottom: '56.25%',  // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
    };

    const playerStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };


    if (loading) return (
        <Stack sx={{ color: 'grey.500' }} spacing={10} direction="row" justifyContent="center">
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
        </Stack>
    )
    if (error) return <div>Error: {error.message}</div>;


  return (
    <Container>
      <Helmet>
        <title>Explore {capitalize(itemName)}: Top Attractions & Tips | Jet Set Travel Guides</title>
        {/* other metadata */}
      </Helmet>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              {capitalize(itemName)}
              <YouTube videoId={data.video_link.split("/").pop()} opts={youtubeOptions} />
            </Typography>
            <Typography align="left" color="text.secondary" paragraph style={{ whiteSpace: 'pre-line' }}>
              {data.description}
            </Typography>
          </Container>
        </Box>
      </main>
      <Footer/>
    </Container>
  );
}
