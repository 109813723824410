/* eslint-disable */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import FlightIcon from '@mui/icons-material/Flight';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { capitalize } from '@mui/material/utils';
import YouTube from "react-youtube";
import useFetch from './useFetch.ts';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';


export default function Cards({ item_list }) {
    const { data, loading, error } = item_list;    
  
    if (loading) return (
        <Stack sx={{ color: 'grey.500' }} spacing={10} direction="row" justifyContent="center">
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
        </Stack>
    )
    if (error) return <div>Error: {error.message}</div>;
  
    return (
        <Grid container spacing={4}>
            {data.map((data_entry) => (
              <Grid item key={data_entry.name} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardActionArea href={"/places/" + data_entry.name}>
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={data_entry.thumbnail}
                  />
                  {/* <YouTube videoId="{{ city.video_link }}" options={options} onReady={this._onReady}/> */}
                  </CardActionArea>

                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography gutterBottom variant="h6" component="h4">
                      {capitalize(data_entry.name)}
                    </Typography> */}
                    <Typography >
                      {data_entry.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Link href={data_entry.video_link.video_link}>
                        <Button size="small"><YouTubeIcon/>YouTube</Button>
                    </Link> */}
                    <Link href={"/places/" + data_entry.name}>
                        <Button size="small">More</Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
    )
}
